import axios from "axios";

async function Manger_check_get() {
    const token = localStorage.getItem("token");
    console.log(token);


    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/manager/v1/manager`,
            method: 'GET',
            headers: {
                Authorization: token
            },
        });
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return true;
    } catch (error) {
        if(error.response.status === 401)
        {
            console.log('확인 에러: ', error.response.status);
            return false;
        }
        else
        {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }


}

export default Manger_check_get;