import axios from "axios";
import {useState} from "react";

async function Creat_post(managerId, password, name, role) {


    const data = {
        "managerId": managerId,
        "password": password,
        "name": name,
        "role": role,
    }

    try {
        const response = await axios({
            headers : {
                "Content-Type":  "application/json",
                Authorization: localStorage.getItem("token")
            },
            url: 'https://admin.clairaudience.co.kr/api/admin/v1/manager',
            method: 'POST',
            data: data,
            withCredentials: true,
        });
        if (response.status === 200) {
            console.log("성공");
        } else {
            console.log("일단 성공");
        }
        return true;
    } catch (error) {
        if (error.response.status === 401) {
            return 401;
        } else {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }


}

export default Creat_post;