import {useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import Creat_post from "../../http/POST/Creat_post";
import {useOutletContext} from "react-router";

const A_UserCreate_page = () => {

    const setModalOpen = useOutletContext().setModalOpen;
    const ulp = useOutletContext().UserList_page_change;

    const [role, setRole] = useState("MANAGER");
    const [pw_same, setPw_same] = useState(true);

    const [password, setPassword] = useState(false);
    const [confirmPassword,setConfirmPassword] = useState(false);
    const [name,setName] = useState(false);
    const [id,setId] = useState(false);

    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_head"}></div>
            <div className={"user_list_page_body"}>
                <div className={"user_data"}>
                    <div className={"user_data_main"}>
                        <div className={"test"}>
                            <h1>Create Family</h1>
                        </div>
                        <table>
                            <tbody>
                            <tr>
                                <th>ID</th>
                                <td>
                                    <input type={"text"} onChange={(e) => setId(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <th>password</th>
                                <td>
                                    <input type={"password"} onChange={(e) => setPassword(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <th>password 확인</th>
                                <td>
                                    <input type={"password"} onChange={(e) => setConfirmPassword(e.target.value)}/>
                                    {
                                        pw_same ? null:
                                        <div style={{color:"red"}}>password 다릅니다.</div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input type={"text"} onChange={(e) => setName(e.target.value)}/>
                                </td>
                            </tr>
                            <tr>
                                <th>역할</th>
                                <td>
                                    <Dropdown className={"role-dropdown-create-page"}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {role}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={()=>{setRole("ADMIN")}}>ADMIN</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>{setRole("ENGINEER")}}>ENGINEER</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>{setRole("MANAGER")}}>MANAGER</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>


                            </tbody>
                        </table>
                        <div className={"btn-create"}>
                            <button className="noselect" onClick={async () => {

                                if(id !== false && password !== false && name !== false && confirmPassword !== false)
                                {
                                    if(password=== confirmPassword)
                                    {
                                        let chack = await Creat_post(id,password,name,role);

                                        if(chack === true)
                                        {
                                            alert("생성완료");
                                            ulp();
                                        }
                                        else if(chack === 401)
                                        {
                                            setModalOpen(true);
                                        }
                                        else {
                                            console.log("error 발생");
                                        }

                                    }else{
                                        setPw_same(false);
                                    }
                                }
                                else{
                                    alert("부족한 부분이 있습니다.");
                                }
                            }}><span className='text'>유저생성</span><span className="icon"><svg
                                xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"><path
                                d="M20.285 6.528l-10.285 10.285-4.285-4.285-3 3 7.285 7.285 13.285-13.285z"/></svg></span>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default A_UserCreate_page;