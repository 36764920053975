/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import './Login.css';
import './css_test/slider.css'
import './css_test/ initial page.css'
import './css_test/login page.css'
import './Modal/Modal_page.css'
import Login_Page from "./page_test/login";
import Initial_page from "./page_test/initial_page";
import Modal_page from "./Modal/Modal_page";
import Manger_check_get from "./test/Manger_check_get";
import Role_check_get from "./test/Role_check_get";



function Login() {

    const [isVisible, setIsVisible] = useState(false);
    const [login,setLogin] = useState(false);
    const [check,setCheck] = useState(false);
    const [modalOpen,setModalOpen] = useState(false);
    const [role,setRole] = useState(false);

    const [token, setToken] = useState(localStorage.getItem("token"));

    const check_link = async () => {
        let lo = await Manger_check_get();
        console.log(lo);
        return lo;
    }


    useEffect(() => {
        async function test() {
            const storedCount = localStorage.getItem("check");
            if(storedCount !== null){
                if(storedCount === "true")
                {
                    const bool = check_link();
                    setCheck(JSON.parse(storedCount));
                    setLogin(JSON.parse(storedCount));
                }
                else
                {
                    setCheck(JSON.parse(storedCount));
                }
            }
        }
        test();

    },[])

    useEffect(() => {

        async function role_check() {
            const userRole = await Role_check_get(token);
            if(userRole.role === "ADMIN")
            {
                console.log(userRole.role);
                setRole(true);
            }
            else if(userRole === 401)
            {
                setModalOpen(true);
            }
            else if(userRole === "error"){
                console.log("서버오류");
            }else{
                setRole(false);
            }
        }
        localStorage.setItem("check",check.toString());
        if(check===true)
        {
            role_check();
        }
    },[check])

    const check_on = () => setCheck(true);
    const check_off = () => setCheck(false);

    const input_token = (token)=> {
        setToken(token);
        localStorage.setItem("token",token);
    };

    const reset_token = ()=> {
        setToken("");
        localStorage.setItem("token","");
    };

    const page_mooving = () => {
        setIsVisible(!isVisible);
        if(isVisible)
        {
            document.querySelector('.container-test').style.transform = 'translate(0vw)';
        }
        else {
            document.querySelector('.container-test').style.transform = 'translate(-100vw)';
        }
    }

    return (
        <div className={"all_page"}>
            <div style={{overflow: "hidden"}}>
                <Modal_page modalOpen={modalOpen} setModalOpen={setModalOpen}/>
                <div className={"container-test"}>
                    <Initial_page onMove_page={page_mooving} login={login} setLogin={setLogin} check_off={check_off}
                                  setModalOpen={setModalOpen} role={role} reset_token = {reset_token}/>
                    <Login_Page  input_token = {input_token} onMove_page={page_mooving} setLogin={setLogin} check_on={check_on}/>
                </div>
            </div>
        </div>
    );
}

export default Login;
