import axios from "axios";

async function C_Cumulative_number_measured_get(setCumulative_measured_data) {

    axios.get(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/statistics/cumulative-number-of-measured-data-points`,{
        headers:{
            Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
        }
    })
        .then(response => {
            console.log("measured");
            console.log(response.data);

            response.data.labels = response.data.labels.map(label=>label.split('T')[0]);
            console.log(response.data);
            setCumulative_measured_data(response.data);

        })
        .catch(error => {
            console.log("실패");
        });


}

export default C_Cumulative_number_measured_get;