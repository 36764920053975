import {useRef, useState} from "react";

const Modal_page = ({modalOpen,setModalOpen}) => {

    const modalBackground = useRef();

    return (
        <div>
            {
                modalOpen &&
                <div className={'login-modal-container'} ref={modalBackground} onClick={e => {
                    if (e.target === modalBackground.current) {
                        setModalOpen(true);
                    }
                }}>
                    <div className={'login-modal-content'}>
                        <p>세션이 만료되었습니다.</p>
                        <button className={'modal-close-btn'} onClick={() => {

                            window.location.href = "/error-401";

                        }}>
                            확인
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default Modal_page;