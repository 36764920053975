import axios from "axios";

function C_Cumulative_number_users_get(setCumulative_users_data) {

    axios.get(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/statistics/cumulative-number-of-users`,{
        headers:{
            Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
        }
    })
        .then(response => {

            console.log("users");
            console.log(response.data);


            response.data.labels = response.data.labels.map(label=>label.split('T')[0]);
            console.log(response.data);
            setCumulative_users_data(response.data);
        })
        .catch(error => {
            console.log("실패");
        });


}

export default C_Cumulative_number_users_get;