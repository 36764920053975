import React, {useState} from "react";
import {FaArrowLeft} from "react-icons/fa";
import Login_post from "../test/http_login";

function Login_Page({onMove_page, setLogin, check_on,input_token}) {
    const [isActive, setIsActive] = useState(false);

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const toggleVibration = () => {
        setIsActive(true);
        setTimeout(() => setIsActive(false), 200); // 1초 후에 진동 중지
    };

    const login_play = async () => {
        const login_check = await Login_post(userId, password);

        if (login_check === false) {
            setLogin(false);
            toggleVibration();
        } else {
            input_token(login_check.data)
            setLogin(true);
            setUserId("");
            setPassword("");
            check_on();
            onMove_page();
        }
    }


    return <div className={"inner"}>
        <div className={isActive ? "login-page vibration" : "login-page"}>
            <div className="login-wrapper">
                <button id={"back_btn"} onClick={() => {
                    onMove_page();
                }}><FaArrowLeft id={"icon_btn"}/></button>
                <h2>Login</h2>
                <div id="login-form">
                    <input type="text" value={userId} name="userId" placeholder="아이디"
                           onChange={(e) => setUserId(e.target.value)}/>
                    <input type="password" value={password} name="password" placeholder="비밀번호"
                           onChange={(e) => setPassword(e.target.value)}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   event.preventDefault();
                                   login_play();
                               }
                           }}
                    />

                    <input type="submit" value="Login" onClick={async () => {
                        login_play();
                    }}
                    />
                </div>
            </div>
        </div>
    </div>
}


export default Login_Page;