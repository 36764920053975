import axios from "axios";

async function Download_ppg_get(measureId) {

    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-cardio/measure/ppg/${measureId}`,
            method: 'GET',
            headers:{
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
            }
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }
    } catch (error) {
        if(error.response.status === 401)
        {
            return 0;
        }
        else
        {
            return 1;
        }
    }


}

export default Download_ppg_get;