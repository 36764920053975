import {Line} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import Chart_Data from "./chart";
import Test_cardio_pcg_get from "../../../http/GET/Test_cardio_pcg_get";
import Test_cardio_ppg_get from "../../../http/GET/Test_cardio_ppg_get";



async function streamToByteArray(responseStream) {
    const reader = responseStream.getReader();
    const chunks = [];
    let done, value;

    while ({done, value} = await reader.read(), !done) {
        chunks.push(value);
    }

    // 합쳐진 데이터를 하나의 Uint8Array로 변환
    let length = chunks.reduce((sum, chunk) => sum + chunk.length, 0);
    let byteArray = new Uint8Array(length);
    let offset = 0;

    for (let chunk of chunks) {
        byteArray.set(chunk, offset);
        offset += chunk.length;
    }

    return byteArray;
}

function byteArrayToFloatArray(byteArray, sampleWidth) {
    if (sampleWidth !== 2 && sampleWidth !== 4) {
        throw new Error('Not supported Sample Width.  Your SampleWidth is ' + sampleWidth);
    }

    const numSamples = byteArray.length / sampleWidth;
    const floatArray = new Float32Array(numSamples);

    for (let i = 0; i < numSamples; i++) {
        // 바이트 오프셋
        const offset = i * sampleWidth;

        let sample;
        if (sampleWidth === 2) {    // 2 이면 16비트 ( 시네스퍼 기본 비트 )
            // 16비트 PCM 일기
            sample = byteArray[offset] | (byteArray[offset + 1] << 8);
            // 16비트 PCM -32768 ~ 32767 범위
            if (sample & 0x8000) {
                sample -= 0x10000;
            }
            floatArray[i] = sample / 32768.0;
        } else if (sampleWidth === 4) {     // 4 이면 32비트
            // 32비트 PCM 데이터 읽기 (대부분 24비트 PCM 데이터는 4바이트로 표현됨)
            sample = byteArray[offset] | (byteArray[offset + 1] << 8) | (byteArray[offset + 2] << 16) | (byteArray[offset + 3] << 24);
            // 32비트 PCM은 -2147483648 ~ 2147483647 범위
            floatArray[i] = sample / 2147483648.0;
        }
    }

    return floatArray;
}



function C_Graph({measureId}) {

    const [graph_data, setGraph_data] = useState(null);
    const [error, setError] = useState(null);
    const [pcg_d,setPcg_d] = useState(null);
    const [ppg_d,setPpg_d] = useState(null);

    useEffect(() => {

        console.log("test ddd")
        Test_cardio_pcg_get(measureId,setPcg_d,setError);
        Test_cardio_ppg_get(measureId,setPpg_d,setError);

    }, []);

    useEffect(() => {


        if (pcg_d !== null && ppg_d !== null) {

            //console.time(`Chart Generation ${measureId}`);
            streamToByteArray(pcg_d).then(response => {

                const byte_data = response;
                const sampleWidth = 2; // 16비트 고정 (하드코딩)
                const floatArray = byteArrayToFloatArray(byte_data, sampleWidth);

                if (floatArray.length < 80000) {
                    setError('PCG is too short.');
                } else if (ppg_d.length < 500) {
                    setError('PPG is too short.');
                } else {

                    setGraph_data(Chart_Data(floatArray, ppg_d, measureId));

                }
            });

        }
    }, [pcg_d, ppg_d]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!graph_data) {
        return <div>Loading...</div>;
    }


    console.log("6")

    return(
        <Line
            id={"family-measure-graph"}
            data={graph_data.data}
            options={graph_data.options}/>
    )

}

export default C_Graph;