import axios from "axios";

async function Download_pcg_get(measureId) {




    try {
        const response = await fetch( `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${measureId}`, {
            method: 'GET',
            headers:{
                Authorization: localStorage.getItem("token"),
            }
            // 쿠키는 기본적으로 동일 도메인 내에서는 자동으로 포함됩니다.
        });

        //console.log(response);

        console.log('pcg: ' + response.status);

        const data = response.body;
        //console.log('PCG receive:', response.body);

        console.log('body: '+ data)
        if(response.status===500)
        {
            return 500;
        }
        return data;
    } catch (error) {
        if(error.response.status === 401)
        {
            return 0;
        }
        else
        {
            return 1;
        }
    }

    /*
    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/pcg/${measureId}`,
            method: 'GET',
            responseType: 'arraybuffer'
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            console.log("type: ",typeof response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }
    } catch (error) {
        if(error.response.status === 401)
        {
            return 0;
        }
        else
        {
            return 1;
        }
    }


     */

}

export default Download_pcg_get;