import './Cardio.css';
import './admin_page/DashBoard/DashBoard_page.css';
import './admin_page/ChangePassword/ChangePassword_page.css';
import './admin_page/UserList/UserList_page.css';
import './admin_page/Measure/Measure_page.css';
import './admin_page/UserData/UserData_page.css';
import './admin_page/UserList/UserList_pagenation.css';
import './admin_page/Modal/Modal_page.css';
import './admin_page/Measure/a_tag_style.css';
import './admin_page/UserData/User_delete/User_delete.css';
import './admin_page/Version/Version_page.css';
import './admin_page/Version/Create_version_page.css';
import header_logo_w from "./image_test/header_logo_w.svg";
import {BrowserRouter, Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Modal_page from "./admin_page/Modal/Modal_page";
import Manger_check_get from "./http/GET/Manger_check_get";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {CiBoxList} from "react-icons/ci";
import {GoGraph} from "react-icons/go";
import Logout_post from "./http/POST/logout_post";
import 'bootstrap/dist/css/bootstrap.min.css';

function useWindowSize() {   //화면 조정
    const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight});

    useEffect(() => {
        const handleResize = () => {
            setSize({width: window.innerWidth, height: window.innerHeight});
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
}

function login_check_function() {
    let check = Manger_check_get();
    return check;
}

function Cardio() {

    const {height} = useWindowSize(); // `height`만 사용하므로 구조 분해 할당을 사용해 추출합니다.
    const [page, setPage] = useState("Dashboard_page");
    const [u_data, setData] = useState();
    const [v_data, v_setData] = useState();
    const [modalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate();

    const Dashboard_page_change = async () => {
        let chack = await login_check_function();
        if (chack === true) {
            navigate("/synesper-lite-cardio");
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Change_password_page_change = async (data) => {
        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/userdata_password/${data.accountId}`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Update_expire_page_change = async (data) => {
        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/userdata_expire/${data.accountId}/${data.expireDate}`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Measure_page_change = async (data) => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/measure/${data.accountId}?page=1`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };
    const UserData_page_change = async (data) => {
        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/userdata/${data.accountId}`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };


    const UserList_page_change = async () => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/userlist?page=1`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Version_page_change = async () => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate("/synesper-lite-cardio/version_page");
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };


    const Updata_version_page_change = async (data) => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/version_page_updata/${data.type}`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Delete_version_page_change = async (data) => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate(`/synesper-lite-cardio/version_page_delete/${data.type}`);
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };

    const Create_version_page_change = async () => {

        let chack = await login_check_function();
        if (chack === true) {
            navigate("/synesper-lite-cardio/version_page_create");
        } else if (chack === false) {
            setModalOpen(true);
        } else {
            console.log("error 발생");
        }
    };


    return (
        <div className={"main"} style={{width: '100%', height: height}}>
            <Modal_page modalOpen={modalOpen} setModalOpen={setModalOpen}></Modal_page>
            <div className={"main_head"}>
                <div className="navigation">
                    <div
                        className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <a href="/"
                           className="me-lg-auto text-white " id={"Cardio-logo"}>
                            <img src={header_logo_w} alt={"logo"}/>
                            <p>CARDIO</p>
                        </a>

                        <ul className="nav ">
                            <li>
                                <a className="nav-link text-white" onClick={() => {
                                    Version_page_change();
                                }}>
                                    <AiOutlineInfoCircle size={"40"}/>
                                    <div>
                                        Version
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="nav-link text-white" onClick={() => {
                                    UserList_page_change();
                                }}>
                                    <CiBoxList size={"40"}/>
                                    <div>
                                        UserList
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a className="nav-link text-white" onClick={() => {
                                    Dashboard_page_change();
                                }}>
                                    <GoGraph size={"40"}/>
                                    <div>
                                        Dashboard
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="text-end" style={{paddingLeft: "60px"}}>
                            <button type="button" className="btn btn-warning" onClick={async () => {


                                const chack = await Logout_post();
                                if (chack) {
                                    localStorage.setItem("check", "false");
                                    window.location.href = "/error-401";
                                } else if (chack === 401) {
                                    setModalOpen(true);
                                } else {

                                }
                            }}>Log out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"main_body"}>


                <Outlet context={{setModalOpen,u_data,v_data,UserList_page_change,Change_password_page_change,
                    Update_expire_page_change,Measure_page_change,Updata_version_page_change,
                    Delete_version_page_change,Create_version_page_change,UserData_page_change,
                    Version_page_change
                }}/>


            </div>
        </div>
    );

}


/*
    {page === "Dashboard_page" && <Dashboard_page/>}
                {page === "Change_password_page" &&
                    <Change_password_page data={data} setModalOpen={setModalOpen} ulp={UserList_page_change}/>}
                {page === "Update_expire_page" &&
                    <UpdateExpire_page data={data} setModalOpen={setModalOpen} ulp={UserList_page_change}/>}
                {page === "Measure_page" && <Measure_page data={data} setModalOpen={setModalOpen}/>}
                {page === "UserData_page" &&
                    <UserData_page data={data} cppc={Change_password_page_change} ulp={UserList_page_change}
                                   setModalOpen={setModalOpen} uepc={Update_expire_page_change}/>}
                {page === "UserList_page" && <UserList_page upc={UserData_page_change} mpc={Measure_page_change}
                                                            setModalOpen={setModalOpen}/>}
                {page === "Version_page" &&
                    <Version_page uvpc={Updata_version_page_change} dvpc={Delete_version_page_change}
                                  cvpc={Create_version_page_change}/>}
                {page === "Updata_version_page" &&
                    <Updata_version_page v_data={v_data} vpc={Version_page_change} setModalOpen={setModalOpen}/>}
                {page === "Delete_version_page" &&
                    <Delete_version_page v_data={v_data} vpc={Version_page_change} setModalOpen={setModalOpen}/>}
                {page === "Create_version_page" &&
                    <Create_version_page vpc={Version_page_change} setModalOpen={setModalOpen}/>}
                {page === "Update_delivery_page" &&
                    <Update_delivery_page data={data} setModalOpen={setModalOpen} ulp={UserList_page_change}/>}
 */

export default Cardio;