import {useEffect, useRef, useState} from "react";
import Delete_user from "../../http/DELETE/Delete_user";
import User_delete from "./User_delete/User_delete";
import UpdateExpire_page from "./UpdateExpire/UpdateExpire_page";
import Manger_check_get from "../../http/GET/Manger_check_get";
import {useOutletContext} from "react-router";
import {useParams} from "react-router-dom";
import User_data_get from "../../http/GET/User_data_get";
import test123 from '../../img/img1.png'


const F_UserData_page = () => {

    const cppc =useOutletContext().Change_password_page_change;
    const ulp =useOutletContext().UserList_page_change;
    const setModalOpen =useOutletContext().setModalOpen;
    const uepc =useOutletContext().Update_expire_page_change;
    const udpc =useOutletContext().Update_delivery_page_change;

    const { id } = useParams(); // useParams를 사용하여 URL의 파라미터를 가져옴


    const [Delete_model_open, setDelete_model_open] = useState(false);
    const [data, setData] = useState({
        "accountId":"",
        "userId":"",
        "userName":"",
        "userEmail":"",
        "delivery":"",
        "deviceSerialNumber":"",
        "date":"",
        "expireData":""
    });

    async function user_data_download () {
        const data = await User_data_get(id);

        if(data===false)
        {

        }
        else if(data==="error")
        {

        }
        else{
            setData(data);
        }
    }

    useEffect(() => {
        user_data_download();
    }, []);



    const User_delete_last_check = async () => {
        const delete_check = await Delete_user(id);

        if(delete_check===410)
        {
            console.log("삭제완료 하였습니다");
        }
        else if(delete_check === 401)
        {
            setDelete_model_open(false);
            setModalOpen(true);
            return;
        }
        else
        {
            alert("실패하였습니다.");
        }
        ulp();
    };

    const tbodyRef = useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const tbodyElement = tbodyRef.current;
        if (!tbodyElement) return;

        const resizeObserver = new ResizeObserver(() => {
            const { offsetWidth: width, offsetHeight: height } = tbodyElement;
            setSize({ width, height });
            console.log(`Width: ${width}px, Height: ${height}px`);
        });

        resizeObserver.observe(tbodyElement);

        return () => {
            resizeObserver.unobserve(tbodyElement);
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <div id={"Information-2"}>
            <div id={"Information-2-contain"}>
                <header>
                    <h2>INFORMATION</h2>
                </header>
                <main className={"Information-2-contain-main"}>
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <th>Account ID</th>
                                <td>{data.accountId}</td>
                            </tr>
                            <tr>
                                <th>ID</th>
                                <td>{data.userId}</td>
                            </tr>
                            <tr>
                                <th>PASSWORD</th>
                                <td>
                                    <div className={"user_data_password"}>
                                        <button onClick={async () => {
                                        }}>change
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>가입날짜</th>
                                <td>
                                    {data.date}
                                </td>
                            </tr>
                            <tr>
                                <th>만료 날짜</th>
                                <td>
                                    {data.expireDate}
                                </td>
                            </tr>
                            <tr>
                                <th>기기 번호</th>
                                <td>{data.deviceSerialNumber}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <div className="timeline" style={{width: `${size.height / 7}px`}}>
                            <div></div>
                            <img className={"circle"} src={test123} alt="My Happy SVG"/>
                            <div className="line"></div>
                            <img className={"circle"} src={test123} alt="My Happy SVG"/>
                            <div className="line"></div>
                            <img className={"circle"} src={test123} alt="My Happy SVG"/>
                            <div></div>
                        </div>
                        <table>
                            <tbody ref={tbodyRef}>
                            <tr>
                                <th>
                                    이름
                                </th>
                                <td>{data.userName}</td>
                            </tr>
                            <tr>
                                <th>
                                    출산예정일
                                </th>
                                <td>{data.delivery}</td>
                            </tr>
                            <tr>
                                <th>
                                    이메일
                                </th>
                                <td>
                                    {data.userEmail}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </main>
                <footer>
                    <div className={"btn-delete"}>
                        <button className="noselect" onClick={() => {

                        }}><span className='text'>유저삭제</span><span className="icon"><svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path
                            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg></span>
                        </button>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default F_UserData_page;