import {useEffect, useRef, useState} from "react";

import UserList_get from "../../http/GET/UserList_get";
import Role_check_get from "../../http/GET/Role_check_get";
import Manger_check_get from "../../http/GET/Manger_check_get";
import UserList_pagenation from "../UserList/UserList_pagenation";
import VersionList_get from "../../http/GET/VersionList_get";
import { FaRegPlusSquare } from "react-icons/fa";
import {useOutletContext} from "react-router";


const C_Version_page = () => {


    const uvpc = useOutletContext().Updata_version_page_change;
    const dvpc = useOutletContext().Delete_version_page_change;
    const cvpc = useOutletContext().Create_version_page_change;


    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.length === 11) {
            return phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        }
        return phoneNumber;
    };


    const [now_page, setNow_page] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [role, setrole] = useState(false);



    /*
        useEffect(() => {
            // 예제 데이터를 사용하여 상태 초기화
            const data = [
                {
                    "type": "android",
                    "version": "1.3"
                },
                {
                    "type": "test",
                    "version": "0.1v"
                }

                ]
            ;
            setUsers(data);
        }, []);
     */




    useEffect(() => {
        const fetchData = async () => {

            const role_data = await Role_check_get();
            if(role_data === 401)
            {

            }else if(role_data === "error")
            {
                console.log("error");
            }else{

                if(role_data.role === "MANAGER")
                {
                    setrole(false);
                }else{
                    setrole(true);
                }
                let data = await VersionList_get(now_page);
                if (data) {
                    setUsers(data.content); // data.content를 상태로 저장
                    setTotalPages(data.page.totalPages); // data.totalPages를 상태로 저장
                } else {
                    console.log("데이터가 없습니다.");
                }
            }
        };

        fetchData();
    }, [now_page]);



    return (
        <div className={"user_list_page"}>
            <div className={"user_list_page_body"}>
                <div className={"검색창"}>
                    <input type="search" placeholder="Search Data..."/>
                    <a onClick={()=>{
                        cvpc();
                    }}><FaRegPlusSquare size={"45"} style={{paddingLeft:"20px"}} /></a>
                </div>
                <div className={"list_page_base"}>
                    <table className={"list_table"}>
                        <thead className={"list_thead"}>
                        <tr className={"list_tr"}>
                            <th className={"list_th"}> type</th>
                            <th className={"list_th"}> 버전</th>
                            <th className={"list_th"}> 업데이트</th>
                            <th className={"list_th"}> 삭제</th>
                        </tr>
                        </thead>
                        <tbody className={"list_tbody"}>

                        {users.map((user,index) => (
                            <tr className={"list_tr"} key={`version_${index}`}>
                                <td className={"list_td"}>{user.type}</td>
                                <td className={"list_td"}>{user.version}</td>
                                <td className={"list_td"}><button
                                onClick={()=>{
                                    uvpc(user);
                                }}
                                >Updata</button></td>
                                <td className={"list_td"}><button
                                onClick={()=>{
                                    dvpc(user);
                                }}
                                >Delete</button></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <UserList_pagenation setNow_page={setNow_page} now_page={now_page} totalPages={totalPages}/>

            </div>
        </div>
    );
};

export default C_Version_page;