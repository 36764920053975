import axios from "axios";

async function F_ExpireSession_post(accountId,setCount,count) {

    axios.post(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/user/disable/${accountId}`,
        {
            headers: {
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
            }
        }
        )
        .then(response => {
            console.log("성공");
            const num = count+1;
            setCount(num);
        })
        .catch(error => {
            console.log("실패");
        });


}

export default F_ExpireSession_post;