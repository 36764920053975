import axios from "axios";

async function Delete_version(type) {

    try {
        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/version/${type}`,
            method: 'DELETE',
            headers: {
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
            }
        });
        return 200;
    } catch (error) {
        if(error.response.status === 401)
        {
            return 401;
        }
        else if(error.response.status === 410)
        {
            console.log('확인 에러: ', error.response.status);
            return 410;
        }
    }

}

export default Delete_version;