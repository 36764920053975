import axios from "axios";

async function Login_post(userId,password,)  {

        const json_login = {
            "account": userId,
            "password": password
        }

        /*
                    const formData = new FormData();
            formData.append('userId', userId);
            formData.append('password', password);
         */

        console.log(json_login)
        try {

            const response = await axios({
                url: 'https://admin.clairaudience.co.kr/api/v1/auth/login',
                method: 'POST',
                data: json_login,
            });
            if (response.status === 200) {
                console.log(response);
                console.log("로그인 성공");
            } else {
                console.log("STATUS : ", response.status);
            }

            return response;
        } catch (error) {
            console.log('로그인 에러: ', error);

            return false;
        }
}

export default Login_post;