import axios from "axios";

async function C_ExpireSession_get(accountId) {

    axios.get(`https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user/expire-session/${accountId}`,{
        headers:{
            Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
        }
    })
        .then(response => {
            console.log("성공");

        })
        .catch(error => {
            console.log("실패");
        });
}

export default C_ExpireSession_get;