import axios from "axios";

async function Logout_post(username,password) {

    try {
        const response = await axios({
            url: 'https://admin.clairaudience.co.kr/api/v1/auth/logout',
            method: 'POST',
            headers: {
                Authorization: localStorage.getItem("token")
            },
        });
        if (response.status === 200) {
            console.log("STATUS : ", response.data);
        } else {
            console.log("STATUS : ", response.status);
        }
        return true;
    } catch (error) {
        if (error.response.status === 401) {
            return 401;
        } else {
            console.log('확인 에러: ', error.response.status);
            return "error";
        }
    }


}

export default Logout_post;