import axios from "axios";

async function Test_ppg_get(measureId, setPpg_d, setError) {
    console.log(localStorage.getItem("token"))

    axios.get(`https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/measure/ppg/${measureId}`,
        {
            headers: {
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
            }
        }
        )
        .then(response => {
            setPpg_d(response.data); // 상태 업데이트
        })
        .catch(error => {

            setError("error: 데이터 없음");

        });


}

export default Test_ppg_get;