import axios from "axios";

async function Logout_post()  {

    try {
        const response = await axios({
            url: 'https://admin.clairaudience.co.kr/api/v1/auth/logout',
            method: 'POST',
            headers: {
                Authorization: localStorage.getItem("token")
            },
        });
        if (response.status === 200) {

        } else {
        }

        return true;
    } catch (error) {
        return false;
    }
}

export default Logout_post;