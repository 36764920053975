import {useEffect, useRef, useState} from "react";
import {
    Chart as ChartJS,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
} from "chart.js";
import {getElementsAtEvent, Line, Bar, Doughnut} from 'react-chartjs-2'
import C_Cumulative_number_measured_get from "../../http/GET/C_Cumulative_number_measured_get";
import C_Cumulative_number_users_get from "../../http/GET/C_Cumulative_number_users_get";


ChartJS.register(
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    BarElement
);

const C_Dashboard_page = () => {

    const [cumulative_measured_data,setCumulative_measured_data] = useState({
        "data": [],
        "lables": []
    });
    const [cumulative_users_data,setCumulative_users_data] = useState({
        "data": [],
        "lables": []
    });


    useEffect(()=>{
        C_Cumulative_number_measured_get(setCumulative_measured_data);
        C_Cumulative_number_users_get(setCumulative_users_data);
    },[])


    const data = {
        labels: cumulative_measured_data.labels,
        datasets: [{
            label: '누적 일별 데이터 개수',
            data: cumulative_measured_data.data,
            borderColor: '#BBB1EE',
            backgroundColor: '#BBB1EE',
            tension: 0.1,
        }]
    };

    const options = {
        maintainAspectRatio: false
    }

    const data2 = {
        labels: cumulative_users_data.labels,
        datasets: [{
            label: '누적 일별 유저수',
            data: cumulative_users_data.data,
            backgroundColor: ['#BBB1EE','#5D7AC5', '#3B468A'],
            borderWidth: 1
        }]
    };

    const options2 = {
        maintainAspectRatio: false
    }



    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);


    const style_a = {
        padding:"4px",
        borderRadius: "10px",
        width:"60px",
        backgroundColor: "rgba(0,0,0,0.1)",
        color:"black"
    }

    return (
        <div className={"user_list_page"}>
            <div className={"dashboard_page"}>
                <div className={"DashBoard"}>
                    <div className={"DashBoard-content"}>
                        <div className={"DashBoard-content-head"}
                             style={{color: "#B3B3B3", fontSize: "18px", fontWeight: "bolder"}}>Dashboard
                        </div>
                        <div className={"DashBoard-content-body"}>
                            <div className={"DashBoard-item p-lg-2"}>
                                <div style={{fontSize: "18px", padding: "5px", fontWeight: "bolder", height: "13%"}}>
                                    Daily Collective Data
                                </div>
                                <div className={"dashboard_page_1"} style={{height: "87%"}}>
                                    <Line
                                        data={data}
                                        options={options}
                                    ></Line>
                                </div>
                            </div>
                            <div className={"DashBoard-item p-lg-2"}>
                                <div style={{fontSize: "18px", padding: "5px", fontWeight: "bolder", height: "13%"}}>
                                    Accumulated User Data
                                </div>
                                <div className={"dashboard_page_2"} style={{height: "87%"}}>
                                    <Bar
                                        data={data2}
                                        options={options2}
                                    >
                                    </Bar>
                                </div>
                            </div>
                            <div className={"DashBoard-item DashBoard-data"}>
                                <div style={{padding: "20px 20px 30px 30px", fontWeight: "bolder", fontSize: "20px"}}>
                                    Dataspaces
                                </div>

                                <div className={"DashBoard-item-object"}>
                                    <div className={"Dataspaces"} style={{width: "100%"}}>
                                        <table style={{width: "100%"}}>
                                            <thead>
                                            <tr>
                                                <th>Accumulated Data</th>
                                                <td>
                                                    Amount
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>누적 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        {
                                                            cumulative_measured_data.data.length !== 0 ?
                                                                cumulative_measured_data.data[cumulative_measured_data.data.length - 1] :
                                                                0
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>누적 유저</th>
                                                <td>
                                                    <div style={style_a}>
                                                        {
                                                            cumulative_users_data.data.length !== 0 ?
                                                                cumulative_users_data.data[cumulative_users_data.data.length - 1] :
                                                                0
                                                        }
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>


                                    <div className={"Dataspaces"} style={{width: "100%"}}>
                                        <table style={{width: "100%"}}>
                                            <thead>
                                            <tr>
                                                <th>Periodic Data</th>
                                                <td>
                                                    Amount
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th>이번 달 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>이번 주 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        87
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>어제 데이터</th>
                                                <td>
                                                    <div style={style_a}>
                                                        {currentDate.getDate()}
                                                    </div>
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default C_Dashboard_page;