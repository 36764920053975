import {FaChevronRight, FaChevronLeft, FaAngleDoubleRight} from "react-icons/fa";
import {FaAnglesLeft} from "react-icons/fa6";



const UserList_pagenation = ({change_page,now_page, setNow_page, totalPages}) => {

    let start_num = Math.floor(now_page/5)*5;
    let end_num = Math.floor(now_page/5)*5+4;

    return (
        <div className={"pagenation_test"}>
            <nav className={"pagination"}>
                <ul>
                    <li className={"page"} onClick={() => {
                        if (start_num - 4 <= 0) {
                            alert("Stop");
                        } else {
                            let data = end_num - 5;
                            change_page(data);
                        }
                    }}><FaAnglesLeft id={"left_bt2"}/></li>


                    <li className={"page"} onClick={() => {
                        if (now_page <= 0) {
                            alert("Stop");
                        } else {
                            let data = now_page - 1;
                            change_page(data);
                        }
                    }}><FaChevronLeft id={"left_bt2"}/></li>

                    {Array.from({length: totalPages}, (_, index) => index)  // 배열 생성 후 filter로 필터링
                        .filter(index => index >= start_num && index <= end_num)  // index가 10 이상 20 이하인 요소만 필터링
                        .map(index => (   // 필터링된 배열을 map으로 렌더링
                            <li className={`page ${now_page === index && "now_page"}`}
                                onClick={() => change_page(index)}
                                key={`UserList_pagenation${index + 1}`}>
                                {index + 1}
                            </li>
                        ))
                    }

                    <li className={"page"} onClick={() => {

                        if (totalPages - 1 <= now_page) {
                            alert("Stop");
                        } else {
                            let data = now_page + 1;
                            change_page(data);
                        }

                    }}><FaChevronRight id={"right_bt2"}/></li>


                    <li className={"page"} onClick={() => {

                        if (totalPages - 1 <= start_num + 5) {
                            alert("Stop");
                        } else {
                            let data = end_num + 1;
                            change_page(data);
                        }

                    }}><FaAngleDoubleRight id={"right_bt2"}/></li>

                </ul>
            </nav>
        </div>
    );
};

export default UserList_pagenation;