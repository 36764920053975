import axios from "axios";

async function MeasureList_get(account,now_page) {

    //url: `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-cardio/user?page=${now_page}&size=20`,

    try {

        const response = await axios({
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-cardio/measure/${account}?page=${now_page}&size=5`,
            method: 'GET',
            headers:{
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
            }
        });
        if (response.status === 200) {
            console.log("성공");
            console.log("STATUS : ", response.data);
            return response.data;
        } else {
            console.log("STATUS : ", response.status);
        }
    } catch (error) {
        console.log('로그인 에러: ', error);
    }


}

export default MeasureList_get;