import axios from "axios";


async function Update_delivery_put(userId, date) {

    try {
        const response = await axios({
            headers : {
                Authorization: localStorage.getItem("token"),  // local에 있는 token에 value값을 출력
                "Content-Type":  "application/json"
            },
            url: `https://admin.clairaudience.co.kr/api/manager/v1/synesper-lite-family/user/${userId}`,
            method: 'PUT',
            data: date,
            //withCredentials: true   cookie 할떄 같이 전송해주는 메뉴
        });
        if (response.status === 200) {
            console.log("성공");
        } else {
            console.log("일단 성공");
        }
        return true;
    } catch (error) {
        console.log(error.response);
        if(error.response.status === 401)
        {
            return false;
        }
        else
        {
            return "error";
        }
    }


}

export default Update_delivery_put;