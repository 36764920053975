import axios from "axios";


async function Create_version_post(date) {

    try {
        const response = await axios({
            headers : {
                "Content-Type":  "application/json"
            },
            url: `https://admin.clairaudience.co.kr/api/engineer/v1/synesper-lite-family/version`,
            method: 'POST',
            data: date,
            withCredentials: true,
            Authorization: localStorage.getItem("token"),
        });
        if (response.status === 200) {
            console.log("성공");
        } else {
            console.log("일단 성공");
        }
        return true;
    } catch (error) {
        console.log(error.response);
        if(error.response.status === 401)
        {
            return false;
        }
        else
        {
            return "error";
        }
    }


}

export default Create_version_post;